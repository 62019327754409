<div class="container">
    
    <div class="row mt-3">
        <div class="col-md-12 text-center p-4">
          
            <img src="./assets/Logotipo_v2.png" alt="" class="img-responsive">
        
        </div>
    </div>
   
    <div class="row mt-4"></div>
    <div >
        <div class="col-12">
            <form (ngSubmit)="login()">
                <div class="row" *ngIf="!token">

                    <!-- <h4 class="full-width text-center mt-2" style="color: #023171;"> Asistencia psicológica </h4> -->
                    <div class="col-12" *ngIf="recoverPassword" style="width: 100%;">
                        <h5 style="text-align: center;">Ingresa tu correo para enviarte un link de recuperación de contraseña</h5>
                    </div>
                    <div class="col-12">
                        <mat-form-field class="full-width epilogue">
                            <input [(ngModel)]="email" name="email" matInput placeholder="Correo">
                        </mat-form-field>
                    </div>
                    <div class="col-12" *ngIf="!recoverPassword">
                        <mat-form-field class="full-width epilogue">
                            <input [(ngModel)]="password" matInput placeholder="Contraseña" name="password" [type]="hide ? 'password' : 'text'">
                            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div style="display: flex; justify-content: flex-end;" *ngIf="!recoverPassword">
                        <!--<a [routerLink]="['/recuperar']"  > Recuperar Contraseña </a>-->
                        <a (click)="recoverPass(true)" class="txt-main" > Recuperar contraseña </a>
                    </div>
                    <div class="col-12 mb-3 text-right" *ngIf="recoverPassword" style="margin-bottom: 2rem;">
                        <a (click)="recoverPass(false)" class="txt-main" > Ingresar </a>
                    </div>
                    <div class="col-12">
                        <button type="submit" [disabled]="loading" *ngIf="!recoverPassword"
                            class="full-width text-white epilogue" mat-button style="background-color: #44797B;">
                            <span *ngIf="!loading"> Iniciar sesión </span>
                            <span *ngIf="loading" > Cargando ...</span>
                        </button>
                        <button type="button" [disabled]="loading" *ngIf="recoverPassword"
                            class="full-width bg-main text-white epilogue" (click)="rePass()" mat-button>
                            <span *ngIf="!loading"> Recuperar </span>
                            <span *ngIf="loading" > Cargando ...</span>
                        </button>
                        <!--<p class="full-width text-center mt-2" > ¿No tienes cuenta? <a class="txt-main" style="color: #701009 !important;" [routerLink]="['/register']" > Registrate </a> </p>-->
                        <!-- <p class="full-width text-center mt-2" > Lee nuestro <a class="txt-main" style="color: #701009 !important;" (click)="openPdfModal()"  > aviso de privacidad </a> </p> -->
                    </div>
                    
                    <div style="display: flex; flex-direction: row; justify-content:space-around; margin-top: 3rem; margin-bottom: 1rem; padding-left: 15px; padding-right: 15px;">
                        <a href="https://play.google.com/store/apps/details?id=com.mindhelp.pacient&hl=en" target="_blank" >
                            <img src="./assets/img/Playstore.png" alt="playstore">
                        </a>
                        <a href="https://apps.apple.com/mx/app/mindhelp-paciente/id1503859820" target="_blank" >
                            <img src="./assets/img/Appstore.png" alt="appstore">
                        </a>
                    </div>
                   
                </div>
                <div class="row" *ngIf="token">
                    <div class="col-12">
                        <h3>Ingresa tu nueva contraseña</h3>
                    </div>
                    <div class="col-12">
                        <mat-form-field class="full-width epilogue">
                            <input [(ngModel)]="password" matInput placeholder="Nueva Contraseña" name="password" [type]="hide ? 'password' : 'text'">
                            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <button type="button" [disabled]="loading" (click)="newPass()"
                            class="full-width bg-main text-white epilogue"  mat-button>
                            <span *ngIf="!loading"> Modificar </span>
                            <span *ngIf="loading" > Cargando ...</span>
                        </button>
                    </div>
                    <div class="col-12 mb-3 mt-5 row justify-content-around">
                        <!--<a href="https://play.google.com/store/apps/details?id=com.mindhelp.pacient&hl=en" target="_blank" >
                            <img src="./assets/img/Playstore.png" alt="playstore">
                        </a>
                        <a href="https://apps.apple.com/mx/app/mindhelp-paciente/id1503859820" target="_blank" >
                            <img src="./assets/img/Appstore.png" alt="appstore">
                        </a>-->
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<!--<div class="login-box">
    <form (ngSubmit)="login()" class="col-12" >
        <div class="login-container mt-5">
            <div class="col-12">
                <mat-form-field class="full-width">
                    <input [(ngModel)]="email" name="email" matInput placeholder="Correo">
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class="full-width">
                    <input [(ngModel)]="password" matInput placeholder="Contraseña" name="password" [type]="hide ? 'password' : 'text'">
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="col-12 mb-3 text-right">
                <a [routerLink]="['/recuperar']"  > Recuperar Contraseña </a>
            </div>
            <div class="col-12">
                <button type="submit" [disabled]="loading" class="full-width bg-main text-white" mat-button>
                    <span *ngIf="!loading"> Iniciar sesión </span>
                    <span *ngIf="loading" > Cargando ...</span>
                </button>
                <p class="full-width text-center mt-2" > ¿No tienes cuenta? <a class="txt-main" [routerLink]="['/register']" > Registrate </a> </p>
            </div>
            <div class="col-12 mb-3 mt-5 row justify-content-around">
                <a href="https://play.google.com/store/apps/details?id=com.mindhelp.pacient&hl=en" target="_blank" >
                    <img src="./assets/img/Playstore.png" alt="playstore">
                </a>
                <a href="https://apps.apple.com/mx/app/mindhelp-paciente/id1503859820" target="_blank" >
                    <img src="./assets/img/Appstore.png" alt="appstore">
                </a>
            </div>
        </div>
    </form>
</div>-->
